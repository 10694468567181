

















































import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'
import FlexibleFormFields from '@/calendesk/sections/section/shared/components/FlexibleFormFields.vue'

export default mixins(Section).extend({
  name: 'FlexibleForm1',
  components: { FlexibleFormFields }
})
