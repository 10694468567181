var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section",class:{ 'section-invert': _vm.invertColors }},[_c('v-container',{staticClass:"full-height",attrs:{"fluid":_vm.data.configuration.wb_container_fluid__checkbox__}},[_c('v-row',{staticClass:"full-height",attrs:{"justify":"center"}},[(!_vm.formOnRightSide)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"6"}},[_c('flexible-form-fields',{attrs:{"configuration":_vm.data.configuration,"button":_vm.getCtaButton}})],1):_vm._e(),(!_vm.imageHidden || !_vm.textHidden)?_c('v-col',{style:({ minHeight: ((_vm.data.configuration.wb_height__style_height__) + "px") }),attrs:{"cols":"12","md":"6"}},[(!_vm.imageHidden && _vm.imageOnTop && (!_vm.isMobile || _vm.isMobile && !_vm.data.configuration.wb_image_hide_on_mobile__checkbox__))?_c('v-img',{class:( _obj = {
                  'mr-4': !_vm.isMobile && !_vm.imageOnRightSide && !_vm.imageOnCenter,
                  'ml-2': !_vm.isMobile && _vm.imageOnRightSide && !_vm.imageOnCenter,
                  'mb-4': true,
                  'mx-auto': _vm.imageOnCenter
               }, _obj[_vm.classForImagePosition] = true, _obj[_vm.classForImageRound] = true, _obj ),attrs:{"contain":_vm.data.configuration.wb_image_contain__checkbox__,"src":_vm.getImageUrl('img_1'),"width":_vm.getImageWidth,"height":_vm.getImageHeight}}):_vm._e(),(!_vm.textHidden)?_c('div',{class:{
          'mt-2': _vm.isMobile && !_vm.imageOnRightSide && (_vm.isMobile && !_vm.data.configuration.wb_image_hide_on_mobile__checkbox__),
        },domProps:{"innerHTML":_vm._s(_vm.data.configuration.wb_section_text__html_text__)}}):_vm._e(),(!_vm.imageHidden && !_vm.imageOnTop && (!_vm.isMobile || _vm.isMobile && !_vm.data.configuration.wb_image_hide_on_mobile__checkbox__))?_c('v-img',{class:( _obj$1 = {
                  'mr-4': !_vm.isMobile && !_vm.imageOnRightSide && !_vm.imageOnCenter,
                  'ml-2': !_vm.isMobile && _vm.imageOnRightSide && !_vm.imageOnCenter,
                  'mb-4': true,
                  'mx-auto': _vm.imageOnCenter
               }, _obj$1[_vm.classForImagePosition] = true, _obj$1[_vm.classForImageRound] = true, _obj$1 ),attrs:{"contain":_vm.data.configuration.wb_image_contain__checkbox__,"src":_vm.getImageUrl('img_1'),"width":_vm.getImageWidth,"height":_vm.getImageHeight}}):_vm._e()],1):_vm._e(),(_vm.formOnRightSide)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"6"}},[_c('flexible-form-fields',{attrs:{"configuration":_vm.data.configuration,"button":_vm.getCtaButton}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }