




























import mixins from 'vue-typed-mixins'
import DefaultButton from '@/calendesk/sections/section/components/DefaultButton.vue'
import CPhoneInput from '@/views/components/CPhoneInput.vue'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import APIClient from '@/calendesk/api/APIClient'
import GeneralEmailMessageRequestData from '@/calendesk/models/DTO/Request/GeneralEmailMessageRequestData'
import FormElements from '@/calendesk/sections/section/shared/components/FormElements.vue'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import { stripSurroundingPTags } from '@/calendesk/filters/stripSurroundingPTags'

export default mixins(DraftElement).extend({
  name: 'FlexibleFormFields',
  props: {
    configuration: {
      type: Object,
      required: true
    },
    button: {
      type: Object,
      required: true
    }
  },
  components: { FormElements, CPhoneInput, DefaultButton },
  data () {
    return {
      isLoading: false,
      formData: [] as Array<Record<string, any>>,
      successMessage: null
    }
  },
  computed: {
    formElements (): Array<Record<string, any>> {
      return this.configuration.wb_form__dynamic_form__
    }
  },
  methods: {
    handleSend () {
      if ((this.$refs.Form as any).validate()) {
        let htmlContent = ''

        if (this.user) {
          htmlContent += `<p style="margin-bottom: 0;"><strong>${this.$trans('number')}:</strong></p>`
          htmlContent += `<p>${this.user.id}</p>`
          htmlContent += `<p style="margin-bottom: 0;"><strong>${this.$trans('name_and_surname')}:</strong></p>`
          htmlContent += `<p>${this.user.getFullName()}</p>`
          htmlContent += `<p style="margin-bottom: 0;"><b>${this.$trans('email')}:</b></p>`
          htmlContent += `<p>${this.user.email}</p>`

          if (this.user.defaultPhone) {
            htmlContent += `<p style="margin-bottom: 0;"><b>${this.$trans('phone')}:</b></p>`
            htmlContent += `<p>${this.user.defaultPhone.e164}</p>`
          }

          htmlContent += '<hr style="margin-top: 30px; margin-bottom: 30px;"/>'
        }

        if (this.formData && this.formData.length > 0) {
          this.formData.forEach((element: Record<string, any>) => {
            if (element && element.value) {
              if (element.description) {
                htmlContent += `<p style="margin-bottom: 0;"><strong>${stripSurroundingPTags(element.description)}</strong></p>`
              } else {
                htmlContent += `<p style="margin-bottom: 0;"><strong>${stripSurroundingPTags(element.label)}</strong></p>`
              }

              if (element.type === this.dynamicFormTypes.CHECKBOX) {
                htmlContent += `<p>${(element.value ? this.$trans('yes') : this.$trans('no'))}</p>`
              } else {
                htmlContent += `<p>${element.value}</p>`
              }
            }
          })
        }

        this.isLoading = true

        const subject = this.configuration.wb_email_subject__text__

        APIClient.sendGeneralEmailMessage(new GeneralEmailMessageRequestData(subject, htmlContent)).then(() => {
          if (this.configuration.wb_email_form_success_url__url__) {
            window.location.href = this.configuration.wb_email_form_success_url__url__
          } else {
            this.successMessage = this.configuration.wb_email_sent__html_text__
          }
        }).catch((error) => {
          if (error?.response?.data?.code === 'TOO_MANY_REQUESTS') {
            errorNotification('too_many_requests', error, false)
          } else {
            errorNotification(null, error)
          }
        }).finally(() => {
          this.isLoading = false
        })
      } else {
        errorNotification('form_is_invalid', null, false)
      }
    }
  }
})
